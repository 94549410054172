import {InspectionQuestionFieldOptionsModel} from './inspection-question-option.model';

export class InspectionFormQuestionModel {
    public id?: string | null = null;
    public inspectionFormsTableId?: string | null = null;
    public code: string | null = null;
    public question: string | null = null;
    public active: boolean | null = null;

    public gradeD?: boolean | null = null;
    public gradeC?: boolean | null = null;
    public gradeV?: boolean | null = null;

    public created?: Date | null = null;
    public updated?: Date | null = null;

    public inspectionQuestionFieldOptions?: Array<InspectionQuestionFieldOptionsModel> | null = [];

    public inspectionFormQuestionAnswers?: Array<any> | null = null;

    constructor(data?: Partial<InspectionFormQuestionModel>) {
        Object.assign(this, data);
    }
}
